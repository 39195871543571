<!--照明详细状态页面-->
<template>
  <a-spin :spinning="spinning" tip="Loading..." id="LightDetails">
    <div>
      <div class="header">
        <!-- 左上角头部开关 -->
        <div class="header-header">
          <div class="switch" @click.stop>
            <a-switch
              v-model="changeState"
              :default-checked="state"
              :loading="loading"
              :checked-children="$t('airconditiondDevice.a24')" 
              :un-checked-children="$t('airconditiondDevice.a25')"
              @change="onChange"
            />
          </div>
          <div class="deviceName">
            <span v-show="initial">{{ lightName }}</span>
            <!-- 名称修改 -->
            <a-icon
              type="form"
              v-show="initial"
              @click="amendName"
              :style="{ fontSize: '20px', color: '#7682CE' }"
            />
            <a-input
              v-model="lightName"
              v-show="alter"
              ref="input"
              :maxLength="20"
            />
            <a-button v-show="alter" @click="amendCancel" type="primary" ghost>{{
              $t("details.a11")
            }}</a-button>
            <a-button
              v-show="alter"
              type="primary"
              @click="updateName"
              :loading="loading"
              >{{ $t("details.a12") }}</a-button
            >
          </div>
        </div>
        <!-- 返回上一级箭头 -->
        <a-icon
          type="close" 
          :style="{ fontSize: '20px', color: '#7682CE' }"
          @click="goDack"
        />
      </div>
      <div class="main">
        <div class="main-top">
          <div class="main-circle">          
              <div class="main-circle-title">
                {{$t('lightDetails.a1')}}
              </div>
              <div class="main-circle-value">
                {{outVal}}
              </div>
              <div class="main-circle-status">
                {{malDesc}}
              </div>
          </div>
          <div class="main-circle"> 
            <div class="main-circle-title">
                {{$t('lightDetails.a2')}}
              </div>
              <div class="main-circle-value">
                {{inVal}}
              </div>
              <div class="main-circle-status">
                {{malDesc}}
              </div>
          </div>  
        </div> 
        <!-- 曲线图 -->
        <div id="myChart" v-if="isShow" :style="{width: '100%', height: '400px'}"></div>
        <div>
          <a-tabs default-active-key="1" @change="callback">
            <a-tab-pane key="1" :tab="$t('lightDetails.a3')">              
              <my-title :title="title10"></my-title>      
              <my-operateInfo :optInfo="switchSetting.operationInfo" :itemIndex="itemIndex" @transferData="getTransferData" @setting="settingSwitch"></my-operateInfo>
              <my-title :title="title1"></my-title>
              <my-table :commentIds="switchSetting.comment" @transferData="getCommentData"></my-table>
              <div class="main-schedule">
                <my-scheduleCtrl :changeState="scheduleCtrl"></my-scheduleCtrl>
              </div>
              <my-tableB :remark="remark"></my-tableB>
            </a-tab-pane>
            <a-tab-pane key="2" :tab="$t('lightDetails.a4')" force-render>
              <my-title :title="title11"></my-title>      
              <my-statusInfo :optInfo="switchStatus.statusInfo" :itemIndex="itemIndex" @transferData="getTransferData"></my-statusInfo>
              <my-title :title="title1"></my-title>
              <my-table :commentIds="switchStatus.comment" @transferData="getCommentData"></my-table>
              <div class="main-schedule">
                <my-scheduleCtrl :changeState="scheduleCtrl"></my-scheduleCtrl>
              </div>
              <my-title :title="title2"></my-title> 
              <my-tableMS :monitor="switchStatus.monitor" :itemIndex="itemIndex" @transferData="getTransferMonitor"></my-tableMS>
              <my-tableB :remark="remark"></my-tableB>
            </a-tab-pane>
            <a-tab-pane key="3" :tab="$t('lightDetails.a5')">
              <my-title :title="title12"></my-title>      
              <my-alertInfo  :optInfo="malfunction.alertInfo" :itemIndex="itemIndex" @transferData="getTransferData"></my-alertInfo>
              <my-title :title="title1"></my-title>
              <my-table :commentIds="malfunction.comment" @transferData="getCommentData"></my-table>
              <div class="main-schedule">
                <my-scheduleCtrl :changeState="scheduleCtrl"></my-scheduleCtrl>
              </div>
              <my-title :title="title2"></my-title> 
              <my-tableMA :monitor="malfunction.monitor" :itemIndex="itemIndex"  @transferData="getTransferMonitor"></my-tableMA>
              <my-tableB :remark="remark"></my-tableB>
            </a-tab-pane>
            <a-tab-pane key="4" :tab="$t('lightDetails.a6')">
              <my-title :title="title13"></my-title>      
              <my-dimmerInfo  :signalId="lightSetting.signalId" :optInfo="lightSetting.measureInfo" :itemIndex="itemIndex" @transferData="getTransferData"></my-dimmerInfo>
              <my-title :title="title1"></my-title>
              <my-table :commentIds="lightSetting.comment" @transferData="getCommentData"></my-table>              
              <my-tableB :remark="remark"></my-tableB>
            </a-tab-pane>
            <a-tab-pane key="5" :tab="$t('lightDetails.a7')">
              <my-title :title="title14"></my-title>      
              <my-dimmerStatusInfo  :deviceType="deviceType" :optInfo="lightStatus.measureInfo" @transferData="getTransferData"></my-dimmerStatusInfo>
              <my-title :title="title3"></my-title>
              <my-upperAndLower :deviceType="deviceType" :monitor="lightStatus.monitor" @transferData="getTransferMonitor"></my-upperAndLower>
              <my-title :title="title1"></my-title>              
              <my-table :commentIds="lightStatus.comment" @transferData="getCommentData"></my-table>              
              <my-tableB :remark="remark"></my-tableB>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
      <div class="footer">
        <a-button type="primary" @click="onSetting">{{this.$t('lightDetails.d13')}}</a-button>
      </div>           
    </div>
  </a-spin>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import lightTable from "./components/lightTableC";
import lightTableMS from "./components/lightTableMS";
import lightTableMA from "./components/lightTableMA";
import lightTableB from "./components/lightTableB";
import alertInfo from "./components/alertInfo";
import statusInfo from "./components/statusInfo";
import operateInfo from "./components/operateInfo";
import dimmerStatusInfo from "./components/dimmerStatusInfo";
import dimmerInfo from "./components/dimmerInfo";
import upperAndLower from "../common/detail/upperAndLower";
import scheduleCtrl from "./components/scheduleCtrl";
import {getLightDetails,updateAnalogInputObj,updateAnalogOutputObj,updateBinaryData,lightSwitchSetting,updateDeviceName} from '@/api/device';

export default {
  data() {
    return {
      initial: true,
      alter: false,
      initialname: "",
      trend:{},
      isShow:false,
      signal: "", 
      tagPoint:"",
      time:1000,//延迟时间（单位毫秒）
      spinning: false,
      loading: false,
      changeState: false,
      scheduleCtrl: false,
      // title0: this.$t('lightDetails.a8'),      
      title1: this.$t('lightDetails.a9'), 
      title2: this.$t('lightDetails.a10'), 
      title3: this.$t('lightDetails.a15'),
      title10: this.$t('lightDetails.d8'),      
      title11: this.$t('lightDetails.d9'), 
      title12: this.$t('lightDetails.d10'), 
      title13: this.$t('lightDetails.d11'), 
      title14: this.$t('lightDetails.d12'), 
      outVal: "",
      inVal: "",
      malDesc:this.$t('lightDetails.a11'), 
      comment:["","","","",""],
      lightName: "电灯名称",      
      remark:{},
      monitor:{},  
      lightSetting:{},//调光设置
      lightStatus:{},  //调光状态
      switchSetting:{},//开关设置
      switchStatus:{},//开关状态
      malfunction:{},//故障
      measureInfo:{},
      data:{},
      itemIndex:"1",
      selectKey: "1",
      deviceType: Number(this.$route.query.deviceType),//300:照明
    };
  },
  computed: {
    state: function () {
      if (this.$route.query.pv == 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getData();
  },
  watch: {    
    selectKey (val, oldVal) {
      if (val) {
        if(this.isShow){
          this.sleep(this.time).then(()=>{         
            this.drawLine(this.trend, this.signal,this.tagPoint);            
          }) 
        }       
      }
    }
  },
  destroyed() {
    console.log("destroyed");
    window.removeEventListener('resize',this.chartResize());
  },
  methods: {
    // 修改名称时切换输入框的方法
    amendName() {
      this.initialname = this.lightName;
      this.initial = !this.initial;
      this.alter = !this.alter;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 5);
    },
    // 取消修改名称的方法
    amendCancel() {
      this.lightName = this.initialname;
      this.initial = !this.initial;
      this.alter = !this.alter;
    },
    // 修改名称的方法
    updateName() {
      this.loading = true;
      let par = {
          id:this.$route.query.id,//ID
          name: this.lightName,
      };
      console.log("updateLightName par:", par);
      updateDeviceName(par)
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.errorCode == "00") {
            this.initial = !this.initial;
            this.alter = !this.alter;
            this.$message.success(res.msg);
          } else if (res.errorCode == "02") {
            this.$message.error(res.msg);
            this.amendCancel();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sleep(ms) {
      return new Promise(resolve => 
        setTimeout(resolve, ms)
       )
    },
    getCommentData(msg){
      console.log("子组件传参内容 comment:",msg);
      this.comment = msg;
    },
    getTransferData(msg){
      console.log("子组件传参内容 info:",msg);
      this.measureInfo = msg;
    },
    getTransferMonitor(msg){
      console.log("子组件传参内容 monitor:",msg);
      this.monitor = msg;
    },
    callback(key) {
      this.selectKey = key;
      this.refreshData(key);      
    },
    // 页面后退
    goDack() {
      this.$router.go(-1);
    },
    // 页面开关操作方法
    onChange(checked) {
      let status=checked?this.$t('lightDetails.d6'):this.$t('lightDetails.d7');
      this.switchSet(status);
    },

    settingSwitch(status){
      console.log("settingSwitch status:", status);
      this.changeState = status==this.$t('lightDetails.d6')?true:false;
      this.switchSet(status);
    },
    //开关设置
    switchSet(val) {       
      // INFO: 2023-10-08修改(单点控制开关)
      this.loading = true; 
      const detailData = this.data;
      let params = {};
      console.log('detailData:', detailData);
      const switchStatusOptions = {'开': 'on', '关': 'off'};
      if (switchStatusOptions[val]) {
        params.rule = switchStatusOptions[val];
      }
      const switchSettingData = detailData.switchSetting;
      if (switchSettingData.signalId) {
        params.signalId = switchSettingData.signalId;
      }
      if (this.$route.query.id) {
        params.pointId = this.$route.query.id;
      }
      console.log('params:', params);
      lightSwitchSetting(params).then((res) => {
        console.log('res:', res);
        this.loading = false;
        const { status } = res;
        if (status === 200) {
          // 返回成功才更新状态
          this.switchSetting.operationInfo.status = val;
          this.$message.info(this.$t('lightDetails.d14'));    
        } else {
          this.changeState = this.switchSetting.operationInfo.status == this.$t('lightDetails.d6') ? true : false;
          this.$message.error(this.$t('lightDetails.d15')); 
        }
      }).catch((err) => {
        this.loading = false;
        console.log(err);
        this.$message.error(this.$t('lightDetails.d15') + err); 
      });
    },
    getData() {  
      this.spinning = true;    
      let siteId = this.$route.query.siteId;
      let analogPoints = this.$route.query.analogPoints;
      let digitalPoints = this.$route.query.digitalPoints;
      console.log("getLightDetails analogPoints:",analogPoints);
      console.log("getLightDetails digitalPoints:",digitalPoints);
      getLightDetails({ siteId,analogPoints,digitalPoints})
        .then((res) => {
          this.data = res.data;
          console.log("getLightDetails data:",this.data);       

          // INFO: 2023-10-08修改：(增加控制灯光状态)
          const detailData = this.data;
          const { statusInfo = {} } = detailData.switchStatus || {};
          this.changeState = statusInfo.presentValue == 1 ? true : false;

          this.refreshData("1");    
          this.spinning = false;          
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err)
        });
    },
    chartResize(){
        try{
            let o = document.getElementById("myChart");
            if(o!=null){
                this.$echarts.init(document.getElementById("myChart")).resize();
            }
        }catch(e){
            console.log(e);
        } 
    },
    drawLine(trend,signal,tag) {
      // 基于准备好的dom，初始化echarts实例
      console.log("drawLine signal",signal);
      console.log("drawLine trend",trend);
      if(trend==null)
        return;     

      let myChart = this.$echarts.init(document.getElementById("myChart"));
      console.log("drawLine myChart:",myChart);
      
      let trendData = trend.data;
      let categories = trend.categories;
      let unit = trend.yunit+"/"+trend.xunit;
      // let tag = this.temperatureStatus.tagPoint;
      let sname = tag + "-" + trend.name + "-"+this.$t("energy.enedata139")+"（"+unit+"）";
      // 绘制图表
      let option = {
        title: {
          text: sname,
          subtext: trend.yunit,
          left: "6%",
          textStyle: {
            fontSize: 18,
            color: "#000",
            fontWeight: "bold"
          }
        },
        tooltip: {
          trigger: "axis",
           subtext: trend.yunit,
        },
        // x轴配置
        xAxis: {
          type: "category",
          data: categories
        },
        // y轴配置
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: signal,
            data: trendData,
            type: "line",
            smooth: true
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    refreshData(key) {   
      this.itemIndex=key;     
      this.lightName = this.$route.query.lightName;
      console.log('this.data:', this.data);
      if (this.data.lightSetting) {
        this.outVal=this.data.lightSetting.outVal;
      }
      if (this.data.lightStatus) {
        this.inVal=this.data.lightStatus.inVal;  
      }
      if(this.data.malfunction!=null) {      
        if(this.data.malfunction.inVal!=null){
           if(this.data.malfunction.inVal=="0")
              this.malDesc = this.$t('lightDetails.a11') ;  
           else 
              this.malDesc = this.$t('lightDetails.a5'); 
        }
      } 
      this.isShow = false;//是否显示曲线图 
      console.log('key:', key);
      switch(key){        
        case "1":
          this.switchSetting = this.data.switchSetting;
          if(this.switchSetting.comment==null){
            this.switchSetting.comment= ["","", "","", ""];
            this.comment = ["","", "","", ""];
          }
          else{
            this.comment =this.switchSetting.comment;
          }    
          console.log("传参内容1:",this.switchSetting);        
                    
          this.scheduleCtrl= this.switchSetting.scheduleCtrl==1?true:false;          
          this.remark = this.switchSetting.remark;                   
          break;
        case "2":              
          this.trend=this.switchStatus.trendDatas;
          if(this.trend!=null)
            this.isShow=true; 
          if(this.switchStatus.signalType!=null)
            this.signal = this.switchStatus.signalType;
          if(this.switchStatus.tagPoint!=null)
            this.tagPoint=this.switchStatus.tagPoint;
          this.switchStatus = this.data.switchStatus;       
          if(this.switchStatus.comment==null){
            this.switchStatus.comment=["","", "","", ""];
            this.comment = ["","", "","", ""];
          }
          else{
            this.comment =this.switchStatus.comment;
          }          
          this.scheduleCtrl= this.switchStatus.scheduleCtrl==1?true:false;          
          this.remark = this.switchStatus.remark;
          console.log("传参内容2:",this.switchStatus);
          break;
        case "3":
          this.malfunction = this.data.malfunction;
          if(this.malfunction!=null){
            if(this.malfunction.comment==null){
              this.comment = ["","", "","", ""];
            }
            else{
              this.comment =this.malfunction.comment;
            }                   
            this.scheduleCtrl= this.malfunction.scheduleCtrl==1?true:false;          
            this.remark = this.malfunction.remark;             
            console.log("传参内容3:",this.malfunction);
          }        
          break;
        case "4":
          this.lightSetting = this.data.lightSetting;
          this.trend=this.lightSetting.trendDatas;
          if(this.trend!=null)
            this.isShow=true; 
          if(this.lightSetting.signalType!=null)
            this.signal = this.lightSetting.signalType;
          if(this.lightSetting.tagPoint!=null)
            this.tagPoint=this.lightSetting.tagPoint;
          
          if(this.lightSetting.comment==null){
            this.comment = ["","", "","", ""];
          }
          else{
            this.comment =this.lightSetting.comment;
          }              
          this.remark = this.lightSetting.remark;
          console.log("传参内容4:",this.lightSetting);
          break;
        case "5":
          this.lightStatus = this.data.lightStatus; 
          this.trend=this.lightStatus.trendDatas;
          if(this.trend!=null)
            this.isShow=true; 
          if(this.lightStatus.signalType!=null)
            this.signal = this.lightStatus.signalType;
          if(this.lightStatus.tagPoint!=null)
            this.tagPoint=this.lightStatus.tagPoint;
          if(this.data.lightStatus.comment==null){
            this.comment = ["","", "","", ""];
          }
          else{
            this.comment =this.lightStatus.comment;
          }      
          this.remark = this.lightStatus.remark;
          console.log("传参内容5:",this.lightStatus);
          break;
      }
      window.addEventListener("resize", this.chartResize());          
    },
    onSetting(){
      switch(this.selectKey){
        case "1"://开关设置
          this.updateBinaryData(this.switchSetting.signalId,this.switchSetting.signalNo,2);
          break;
        case "2"://开关状态
          this.updateBinaryData(this.switchStatus.signalId,this.switchStatus.signalNo,1);
          break;
        case "3"://故障
          this.updateBinaryData(this.malfunction.signalId,this.malfunction.signalNo,4);
          break;
        case "4"://调光设置
          this.dimmerSetting(this.lightSetting.signalId);
          break;
        case "5"://调光状态
          this.dimmerStatusSetting(this.lightStatus.signalId);
          break;
      }
    },    
    updateBinaryData(signalId,signalNo,pointType){      
      this.loading = true;  
      let par = {
        signalId:signalId,
        signalNo:signalNo,
        pointType:pointType,
        operationInfo:this.measureInfo,
        statusInfo:this.measureInfo,
        alertInfo:this.measureInfo,
        comment:this.comment,
        monitor:this.monitor,
      }; 
      console.log("updateBinaryData par:", par);     
      updateBinaryData(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateBinaryData result", data);
          if(data)
            this.$message.info(this.$t('lightDetails.d14')); 
          else
            this.$message.error(this.$t('lightDetails.d15')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')); 
        }); 
    },
    dimmerSetting(signalId){      
      this.loading = true;  
      let par = {
        signalId:signalId,
        measureInfo:this.measureInfo,
        comment:this.comment,
      }; 
      console.log("updateAnalogOutputObj par:", par);     
      updateAnalogOutputObj(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateAnalogOutputObj result", data);
          this.$message.info(this.$t('lightDetails.d14')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')+err); 
        }); 
    },
    dimmerStatusSetting(signalId){      
      this.loading = true;  
      let par = {
        signalId:signalId,
        measureInfo:this.measureInfo,
        comment:this.comment,
        monitor:this.monitor,
      }; 
      console.log("updateAnalogInputObj par:", par);     
      updateAnalogInputObj(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateAnalogInputObj result", data);
          this.$message.info(this.$t('lightDetails.d14')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')+err); 
        }); 
    },
  },
  components: {
    "my-title": tabletitle,
    "my-table": lightTable,
    "my-tableMS": lightTableMS,
    "my-tableMA": lightTableMA,
    "my-tableB": lightTableB,    
    "my-scheduleCtrl": scheduleCtrl,
    "my-operateInfo": operateInfo,
    "my-statusInfo": statusInfo,
    "my-alertInfo": alertInfo,
    "my-dimmerStatusInfo": dimmerStatusInfo,
    "my-dimmerInfo": dimmerInfo,
    "my-upperAndLower": upperAndLower,
  },
};
</script>

<style scoped>
#LightDetails {
  width: calc(100% - 40px);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px #bdbcbc;
  background: #ffffff;
  overflow: hidden;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 20px 20px 20px 20px;
  border-bottom: 1px solid #cccdcd;
  white-space: nowrap;
  overflow: hidden;
}
.header-header {
  display: flex;
  align-items: center;
}
.header-header span {
  margin-right: 30px;
}
.switch {
  display: flex;
  align-items: center;
}
.deviceName{
  margin-left: 20px;
}
.deviceName span {
  /* color: #898989; */
  white-space: nowrap;
  margin-right: 20px;
}
.deviceName input {
  margin-right: 10px;
}
.deviceName button {
  margin-right: 10px;
}
.main {
  width: 100%;
  padding: 20px 20px 20px 20px;
  /* margin: 0 auto; */
}
.main-top {
  width:500px;
  margin: 0 auto;
}
.main-circle {
  padding:20px;
  width:180px;
  height:180px;
  border-radius: 50%;
  border:1px solid green; 
  display:inline-block;
  text-align:center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.main-circle-title {
  font-size:13px;
  color:#898989;
  margin-top:20%;
}
.main-circle-value {
  font-size:30px;
}
.main-circle-status {
  font-size:13px;
  /* color:red; */
  margin-bottom:30%;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}
</style>