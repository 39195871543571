<!--调光设置-->
<template>
  <div id="dimmerInfo">
    <div class="dimmerInfo-left">
      <div class="row">     
        <span>{{desc}}</span>
        <span style="margin-right:16px;"> {{form.operate}} </span>
        <a-input v-model="form.operate" suffix="cd" style="width: 90px" @change="dataChange"/>
        <span>(0.0~100.0)</span>
        <a-button style="margin-right:32px;;width: 60px" @click="setting">{{$t('lightDetails.d13')}}</a-button>
        <span > {{$t('lightDetails.d1')}}</span> 
        <span style="margin-right:16px;"> {{form.operateTime}}</span> 
        <span style="margin-right:16px;">{{$t('lightDetails.d2')}}{{form.factor}}</span>   
        <a-checkbox v-model= "form.maintenance" style="margin-right:16px;" @change="dataChange"> 
          {{$t('lightDetails.d3')}}
        </a-checkbox>                 
      </div>      
    </div>    
  </div>
</template>

<script>
import {dimmerSetting,switchSetting} from '@/api/device';

export default {
  //父窗口传入参数
  props: {
    itemIndex: String,
    optInfo: Object,
    signalId: {
      type: Number,
      default: 0,
    },
  },
  //本地参数
  data() {
    return {   
      form: {
              operateTime: '',
              factor: '',
              operate: '',
              maintenance: false,
              importantDevice: false,
              voiceType: '',
              options: [{value:"开启"},{value:"关闭"}],
              statusABs:[{value:"A接"},{value:"B接"}],
              statusAB:"A接",
            },      
      desc:this.$t('lightDetails.d11')+'：',
    };
  },   
  mounted(){
    this.setData(this.optInfo);
  },
  methods: {
    dataChange(e){ 
      console.log("dataChange:", this.form);      
      this.$emit('transferData',this.form);
    },
    setting(){
        //调光设置
        this.dimmerSetting();
    },    
    //调光度设置
    dimmerSetting() {       
      this.loading = true;  
      console.log("dimmerSetting signalId:", this.signalId);
      let par = {
          signalId:this.signalId,//ID
          measureInfo: {status:this.form.operate}//信息内容
      };
      console.log("dimmerSetting par:", par);     
      dimmerSetting(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("dimmerSetting result", data);
          this.$message.info(this.$t('lightDetails.d14')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')+err); 
        }); 
    },
    setData(val){
        if(val!=null){
          this.form.operateTime=val.changeDatetime;  
          this.form.factor=val.factor;        
          this.form.operate = val.status==null?"":val.status; 
          this.form.maintenance = val.maintenance;
          this.form.importantDevice=val.importantDevice; 
          this.form.voiceType=val.voiceType;     
          this.form.options=[{value:val.on},{value:val.off}]; 
          this.form.statusAB=val.statusAB;
        }
    },
  },
  watch:{
    optInfo: function (val){    
        // console.log("operateInfo data:", val);   
        this.setData(val);         
    },
  },  
};
</script>

<style scoped>
#dimmerInfo {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*内边距*/
  padding: 20px 20px 20px 20px; 
  
  border-radius: 3px;
  /* 阴影 */
  /* box-shadow: 0px 0px 10px #bdbcbc; */ 
  /* margin-bottom: 20px; */
  overflow: hidden;

  border-left: 1px solid #dedfdf;
  border-right: 1px solid #dedfdf;
  border-bottom: 1px solid #dedfdf;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;

}
.dimmerInfo-left {
  display: flex;
}
p {
  margin-bottom: 0;
}
.row {
  margin-right: 40px;
}
.row span {
  font-size: 13px;
  color: #898989;
  white-space: nowrap;
}
.row p {
  font-size: 16px;
  white-space: nowrap;
}
.dimmerInfo-right {
  display: flex;
  align-items: center;
}
button {
  width: 120px;
  margin-left: 15px;
}
</style>