<!--上下限监视-->
<template>
  <div id="upperAndLower">
    <table>
      <tr class="row">
        <td>
          <a-checkbox class="checkbox" v-model= "monitor.maxMonitor"  @change="StateChanges">  
            {{upperMonitor}} 
          </a-checkbox>
          <span class="desc">{{upperValue}} {{monitor.high}}</span>
          <a-input-number class="input"  v-model= "monitor.highLimit" :disabled="!monitor.maxMonitor" @change="StateChanges">             
          </a-input-number>           
          <span class="desc">{{unit}}</span>
        </td>
      </tr>
      <tr class="row">
        <td> 
          <a-checkbox class="checkbox" v-model= "monitor.minMonitor" @change="StateChanges">  
            {{lowerMonitor}} 
          </a-checkbox>
          <span class="desc">{{lowerValue}} {{monitor.low}}</span>
          <a-input-number class="input"  v-model= "monitor.lowLimit"  :disabled="!monitor.minMonitor" @change="StateChanges">             
          </a-input-number>           
          <span class="desc">{{unit}}</span>
        </td>
      </tr>
      <tr class="row">
        <td>
          <span class="desc">{{fixed}}</span>
          <a-input-number class="input" v-model= "monitor.fixed" @change="StateChanges">             
          </a-input-number>           
          <span class="desc">{{unit}}</span>
          <span class="desc">{{range}}</span>
        </td>
      </tr>                  
    </table>
  </div>
</template>

<script>
// import monitorInfo from "./monitorInfo";
export default {
  props: {
      monitor:{
        type: Object,
        default :function () {
          return {
             maxMonitor: false,
             minMonitor:false,
             highLimit: 0,
             lowLimit:0,
             high:0,
             low:0,
             fixed:0,
           }         
        }
      },
      deviceType:Number,//300:照明;270:气体检测;420:湿度检测;430:温度检测;
  },
  data() {
    return {
       upperMonitor: "上限异常监视",//this.$t('lightDetails.b4'),
       lowerMonitor: "下限异常监视",//this.$t('lightDetails.b5'),
       upperValue:"上限值",
       lowerValue:"下限值",
       fixed:"死区",
       range:"0~(上限值-下限值)",
    }
  },
  methods: {
    StateChanges() {
      this.$emit('transferData',this.monitor);//返回数据至父组件
    },    
  },
  computed: {
    unit: function () {
      //300:照明;270:气体检测;420:湿度检测;430:温度检测;
      let unitDesc ="";
      switch(this.deviceType){
        case 300:
          unitDesc = "cd";
          break;
        case 270:
          unitDesc = "mg/m3";
          break;
        case 430:
          unitDesc = "%";
          break;
        case 420:
          unitDesc = "℃";
          break;
      }
      return unitDesc;
    },
  },

  components: {
  }
};
</script>
<style scoped>
#upperAndLower {
  width: 100%;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
}
table {
  width: 100%;
  border-left: 1px solid #dedfdf;  
  border-right: 1px solid #dedfdf;  
  border-bottom: 1px solid #dedfdf;  
}
.tabletitle td {
  color: #3e3a39;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
}
.tablecontent td {
  padding: 10px 16px;
  font-size: 14px;
}
/* .row{
  border-bottom: 1px solid #dedfdf;  
  margin-bottom: 16px;
} */
.desc{
  padding-left: 16px;
}
.checkbox {
  margin-left: 16px;
  margin-right: 40px;
}
.input{  
  margin-left: 16px;
  width: 80px;
}
</style>