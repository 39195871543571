<!--维护监视（故障）-->
<template>
  <div id="monitorTable">
    <table>         
      <tr class="row4">
        <td>
          <my-info 
            :cbname="alertTime" 
            :unit = 'hour' 
            :showData="monitor.alertTime" 
            @transferData="transferAlertTime"
          ></my-info>
        </td>
      </tr> 
      <tr class="row5">
        <td>
          <my-info 
            :cbname="alertTimes" 
            :unit = 'times' 
            :showData="monitor.alertTimes" 
            @transferData="transferAlertTimes"
          ></my-info>
        </td>
      </tr>         
    </table>
  </div>
</template>

<script>
import monitorInfo from "./monitorInfo";
export default {
  props: {
      monitor: {
        type:Object,
        // default:function () {
        //   return {
        //     cumulativeTime:{},
        //     continuousTime:{},
        //     statusChangeTimes:{},
        //     alertTime:{},
        //     alertTimes:{},
        //   }
        // }
      },
      itemIndex: String,   
  },
  data() {
    return {
       show1:false,
       show2:false,
       cumulativeTimeDesc: this.$t('lightDetails.b1'),
       continuousTimeDesc: this.$t('lightDetails.b2'),
       statusChangeTimesDesc: this.$t('lightDetails.b3'),
       alertTime: this.$t('lightDetails.b4'),
       alertTimes: this.$t('lightDetails.b5'),
       hour:this.$t('lightDetails.b6'),
       times:this.$t('lightDetails.b7'),
       form:{
         cumulativeTime:{},
         continuousTime:{},
         statusChangeTimes:{},
         alertTime:{},
         alertTimes:{},
       }
    }
  },
  mounted(){
    console.log("子组件传参内容 monitor:",this.monitor);
  },
  methods: {
    transferAlertTime(msg){
      this.form.alertTime=msg;
      this.$emit('transferData',this.monitor);//返回数据至父组件
    },
    transferAlertTimes(msg){
      this.form.alertTimes=msg;
      this.$emit('transferData',this.monitor);//返回数据至父组件
    },
  },
  watch:{
    monitor: function (val) {   
      this.form.cumulativeTime = val.cumulativeTime;
      this.form.continuousTime = val.continuousTime;
      this.form.statusChangeTimes = val.statusChangeTimes;
      this.form.alertTime = val.alertTime;
      this.form.alertTimes = val.alertTimes;
    },
  },
  components: {
    "my-info": monitorInfo,
  }
};
</script>
<style scoped>
#monitorTable {
  width: 100%;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
}
table {
  width: 100%;
  border-left: 1px solid #dedfdf;  
  border-right: 1px solid #dedfdf;
}
.tabletitle td {
  color: #3e3a39;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
}
.tablecontent td {
  padding: 10px 16px;
  font-size: 14px;
}
.top {
  border-top: 1px solid #dedfdf;
}
/* .table-button {
  width: 120px;
} */
img {
  width: 36px;
  margin-right: 15px;
}
</style>