<template>
  <div id="alertInfo">
    <div class="alertInfo-left">
      <div class="row">     
        <span>{{desc}}</span>
        <span style="margin-right:16px;"> {{optInfo.status}} </span>
        <a-select :disabled="!form.maintenance" v-model="form.status"  style="margin-right:16px;width: 80px" @change="dataChange">
          <a-select-option v-for="item in form.options" :key="item.value">
            {{item.value}}
          </a-select-option>  
        </a-select> 
        <span > {{$t('lightDetails.d1')}}</span> 
        <span style="margin-right:16px;"> {{form.changeDatetime}}</span> 
        <a-checkbox v-model= "form.maintenance" style="margin-right:16px;" @change="dataChange"> 
          {{$t('lightDetails.d3')}}
        </a-checkbox>     
        <a-select v-model="form.statusAB"  style="margin-right:16px;width: 80px" @change="dataChange">
          <a-select-option v-for="item in form.statusABs" :key="item.value">
            {{item.value}}
          </a-select-option>  
        </a-select>
        <span  style="margin-right:16px;">{{$t('lightDetails.d5')}}</span>  
        <a-select v-model="form.voiceType"  style="margin-right:16px;width: 120px" @change="dataChange">
          <a-select-option v-for="item in form.voiceTypes" :key="item.value">
            {{item.value}}
          </a-select-option>  
        </a-select>
      </div>      
    </div>    
  </div>
</template>

<script>
import {dimmerSetting,switchSetting,switchSettingMultiple} from '@/api/device';

export default {
  //父窗口传入参数
  props: {
    optInfo: Object,
  },
  //本地参数
  data() {
    return {   
      form: {
              changeDatetime: '',
              factor: '',
              status: '',
              maintenance: false,
              importantDevice: false,
              voiceType: '',
              voiceTypes:[{value:"没有警报"},{value:"发生火灾"},{value:"发生停电"},{value:"发生大故障"},{value:"发生小故障"}],
              options: [{value:"未选择"},{value:"故障"},{value:"正常"}],
              statusABs:[{value:"A接"},{value:"B接"}],
              statusAB:"A接",
            },      
      desc:this.$t('lightDetails.d10')+'：',
    };
  },   
  mounted(){
    this.setData(this.optInfo);
  },
  methods: {
    dataChange(e){ 
      console.log("dataChange:", this.form);      
      this.$emit('transferData',this.form);
    },    
    setData(val){
        if(val!=null){
          this.form.changeDatetime=val.changeDatetime;  
          this.form.factor=val.factor;        
          this.form.status = val.status==null?"":val.status; 
          this.form.maintenance = val.maintenance;
          this.form.importantDevice=val.importantDevice; 
          this.form.voiceType=val.voiceType;     
          this.form.options=[{value:"未选择"},{value:val.on},{value:val.off}]; 
          this.form.statusAB=val.statusAB;
        }
    },
  },
  watch:{
    optInfo: function (val){    
        // console.log("operateInfo data:", val);   
        this.setData(val);         
    },    
  },  
};
</script>

<style scoped>
#alertInfo {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*内边距*/
  padding: 20px 20px 20px 20px; 
  
  border-radius: 3px;
  /* 阴影 */
  /* box-shadow: 0px 0px 10px #bdbcbc; */ 
  /* margin-bottom: 20px; */
  overflow: hidden;

  border-left: 1px solid #dedfdf;
  border-right: 1px solid #dedfdf;
  border-bottom: 1px solid #dedfdf;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;

}
.alertInfo-left {
  display: flex;
}
p {
  margin-bottom: 0;
}
.row {
  margin-right: 40px;
}
.row span {
  font-size: 13px;
  color: #898989;
  white-space: nowrap;
}
.row p {
  font-size: 16px;
  white-space: nowrap;
}
.alertInfo-right {
  display: flex;
  align-items: center;
}
button {
  width: 120px;
  margin-left: 15px;
}
</style>